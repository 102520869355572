<template>
  <div class="about_contractor card__body body__text">
    <div class="contractor_image_container">
      <div class="company_image_wrapper" id="company_image_wrapper">
        <img :id="'company_image' + index"
          :src="skillImage(searchedSkill, index + 1)"
          loading="lazy"
          alt=""/>
      </div>
    </div>
    <div class="info">
      <div class="company_verified skeleton-verify"
        :id="'company_verified' + index">
        <svg style="fill: gray" height="30px" viewBox="0 96 960 960">
          <path d="m338 1023-82-138-161-34 19-156L12 576l102-118-19-157 161-33 82-139 142 65 143-65 82 139 160 33-19 157 102 118-102 119 19 156-160 34-82 138-143-65-142 65Zm99-308 232-231-51-47-181 180-93-100-54 52 147 146Z"/></svg><span
          style="width: 100px; margin-top: 10px; margin-left: 5px"
          class="skeleton skeleton-text"
        ></span>
      </div>
      <div class="company_rating font_bold skeleton_star"
        :id="'company_rating' + index">
        <template v-for="star in Math.floor(2)" :key="star">
          <i class="stars filled_stars">&#x2605; </i>
        </template>
        <template v-for="star in 5 - Math.floor(2)" :key="star">
          <i class="stars filled_stars">&#x2606; </i>
        </template>
      </div>
      <div class="company_gst" :id="'company_gst' + index">
        <span class="skeleton-verify">
          <svg height="20px" fill="gray" viewBox="0 -960 960 960" width="24">
            <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q65 0 123 19t107 53l-58 59q-38-24-81-37.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-18-2-36t-6-35l65-65q11 32 17 66t6 70q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-56-216L254-466l56-56 114 114 400-401 56 56-456 457Z"/>
          </svg>
        </span>
        <span style="width: 150px; margin-top: 5px"
          class="skeleton skeleton-text"
        ></span>
      </div>
      <span style="width: 173px; margin-top: 10px; margin-left: 5px"
        class="skeleton skeleton-text"
      ></span>
      <div class="company_name" :id="'company_name' + index"></div>
      <div class="company_type"></div>
      <div class="capability_ids font_bold">
        <div class="capability_id skeleton" style="width: 70px; padding: 10px"></div>
        <div class="capability_id skeleton" style="width: 70px; padding: 10px"></div>
        <div class="capability_id skeleton" style="width: 70px; padding: 10px"></div>
      </div>
      <div class="enquiry">
        <div class="chat">
          <button class="text_default_black" style="width: 75px; padding: 12px; margin-top: 3px"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnloadedCards",
  props: ["searchedSkill", "contractorSkills", "index"],
  methods: {
    skillImage(capabilityId, index) {
      let images = this.contractorSkills[capabilityId].images;
      return require(`@/assets/img/skillsImages/${images[index]}.jpg`);
    },
  },
};
</script>

<style>
.font_bold {
  font-weight: 700;
}

.text_default_black {
  color: rgb(80, 80, 80);
}

.contractor_image_container {
  width: 45%;
  display: flex;
  align-items: center;
}

.company_image_wrapper > img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 8px;
}

.about_contractor {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.company_name {
  text-transform: capitalize;
  font-size: 1rem;
  color: black;
}

.company_gst,
.company_verified {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}

.company_gst {
  align-items: center;
}

.company_verified {
  color: rgb(33, 122, 0);
  align-items: center;
  font-weight: 600;
  fill: rgb(33, 122, 0);
}

.company_rating {
  color: #fd7a15 !important;
}

.capability_ids {
  display: flex;
  justify-content: flex-star;
  flex-wrap: wrap;
  gap: 12px;
}

.stars {
  font-size: 1.5rem;
  font-style: normal;
}

.capability_id {
  font-size: 0.75rem;
  border: 1px solid rgb(193, 193, 193);
  background-color: rgb(239, 239, 239);
  padding: 0 8px;
  color: rgb(111, 111, 111);
  border-radius: 4px;
}

.enquiry {
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
}

.enquiry > div > button {
  border-radius: 4px;
  padding: 6px 16px;
  border: none;
  font-weight: 600;
  font-size: 0.85rem;
}

.chat > button {
  background-color: rgb(229, 229, 229);
  display: flex;
  align-items: center;
  gap: 6px;
}
.info{
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 4px;
}

/* media queries */
@media screen and (max-width: 550px) {
  .selectSkill {
    padding: 4px;
    height: 30px;
    font-size: 0.85rem;
    width: fit-content;
  }

  .searchJob input {
    padding: 4px;
    width: 110px;
    letter-spacing: normal;
    height: 30px;
  }

  .searchBtn {
    height: 30px;
    width: 100%;
  }

  .enquiry > div > button {
    border-radius: 4px;
    padding: 4px 6px;
    border: none;
    font-size: 0.75rem;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton_star {
  animation: skeleton-loading_star 1s linear infinite alternate;
  color: gray !important;
}

@keyframes skeleton-loading_star {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 80%;
  }
}

.skeleton_img {
  animation: skeleton-loading_star 1s linear infinite alternate;
  background: rgba(128, 128, 128, 0.732);
  border: 1px solid gray;
  border-radius: 5px;
  width: 80%;
  max-width: 280px;
}

@keyframes skeleton-loading_star {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 20%;
  }
}

.skeleton-verify {
  animation: skeleton-loading_star 1s linear infinite alternate;
}

@keyframes skeleton-loading_star {
  0% {
    opacity: 10%;
  }
  100% {
    opacity: 80%;
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
}
</style>
