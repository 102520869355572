<template>
  <div class="constra-page-container">
    <div class="constra-header">
      <div class="constra-container">
        <img src="@/assets/img/constra/32543234_transparentO-4.png" alt="CONSTRA" style="max-width: 150px;">
      </div>
    </div>

    <section class="constra-hero">
      <h1>CONSTRA unlocks the power of visual intelligence</h1>
      <h2>To manage construction projects</h2>
      <!-- <a href="#" class="constra-button">KNOW HOW</a> -->
    </section>

    <section class="constra-feature-section">
      <div class="constra-feature-text">
        <h3>CONSTRA Build</h3>
        <p>Full cycle construction management</p>
        <p>Visually monitor the projects, measure progress against the plan, manage defects, and configure automated
          reports - all remotely</p>
        <a href="#" class="constra-button">VIEW DETAILS</a>
      </div>
      <div class="constra-feature-text">
        <img src="@/assets/img/constra/constra-build-main.png" alt="CONSTRA Build">
      </div>
    </section>

    <section class="constra-feature-section">
      <div class="constra-feature-text">
        <h3>CONSTRA Survey</h3>
        <p>Precise and meticulous land surveys</p>
        <p>View your land in 3D, accurately overlay survey drawings, master plans and leverage a host of topographical
          and cadastral survey tools</p>
        <!-- <a href="#" class="constra-button">VIEW DETAILS</a> -->
      </div>
      <div class="constra-feature-text">
        <img src="@/assets/img/constra/constra-survey-main.png" alt="CONSTRA Survey">
      </div>
    </section>

    <section class="constra-feature-section">
      <div class="constra-feature-text">
        <h3>CONSTRA Scouter</h3>
        <p>Evaluate multiple land parcels - technically and remotely</p>
        <p>An ideal solution to evaluate multiple land-parcels or project-sites, before making a buying decision.</p>
        <!-- <a href="#" class="constra-button">VIEW DETAILS</a> -->
      </div>
      <div class="constra-feature-text">
        <img src="@/assets/img/constra/constra-scouter-main.png" alt="CONSTRA Scouter">
      </div>
    </section>

    <section class="constra-outcomes">
      <div class="constra-content">
        <h2>CONSTRA ensures exceptional constra-outcomes</h2>
        <p>CONSTRA enables real estate developers, PMCs, construction executives, and financiers to drive efficiency,
          transparency, quality, reduce costs, and ensure on-time project completion.</p>

        <iframe width="80%" height="315" src="https://www.youtube.com/embed/CdNHUMHyOTA?si=OT7d3HO9-WeQHV0r"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


      </div>
      <div class="outcome-stats">
        <div class="constra-stat">
          <h3>60%</h3>
          <p>Progress and quality checks done remotely</p>
        </div>
        <div class="constra-stat">
          <h3>80%</h3>
          <p>Reduction in site visits by management team</p>
        </div>
        <div class="constra-stat">
          <h3>99%</h3>
          <p>Fewer reworks in the construction projects</p>
        </div>
        <div class="constra-stat">
          <h3>20%</h3>
          <p>Reduction in overall project cost</p>
        </div>
      </div>
    </section>


    <section class="constra-testimonials">
      <h2>Our constra-testimonials</h2>
      <div class="constra-testimonial">
        <p>"At RMZ, we are delighted with Constra. It has helped streamline our construction progress reporting
          really well with its automated visual data analytics. It also eased our project-related communications on
          snags and media with its mobile app. We are excited about the new constra-features they are bringing in! Wishing
          Huviar the very best!"</p>
        <p><strong>Viswanatha KN, Associate Director - RMZ</strong></p>
      </div>
      <div class="constra-testimonial">
        <p>"Constra has enabled us to efficiently monitor our construction projects and has provided us with
          valuable insights. The team is responsive and has been a pleasure to work with. Highly recommended!"</p>
        <p><strong>Jagvinder Pinny Kumar, Sr. Portfolio Manager, Microsoft</strong></p>
      </div>
      <div class="constra-testimonial">
        <p>"Constra is a futuristic technology that will replace manual and time taking tasks while archiving in depth
          information for posterity. It is a solution that is beyond just static images and videos but contains deep
          analysis of site activities and conditions, that can be used for critical decisions"</p>
        <p><strong>Ajay Kumar D, Senior Executive Director, CBRE South Asia, </strong></p>
      </div>
      <div class="constra-testimonial">
        <p>" We have been using CONSTRA for many months now and it has been an amazing experience for us. Right from
          quick drone based land surveying to automated visual data based construction monitoring, it has helped ease
          our processes and saved us a tremendous amount of time and effort. The software is world class and the team is
          fabulous!"</p>
        <p><strong>Nagendra M, Sr. Vice President, Century Group</strong></p>
      </div>
    </section>
  </div>

</template>

<script>
export default {
  name: 'ConstraComponent'

}
</script>

<style scoped>
 .constra-page-container {
   display: block;
   margin-top: 85px;
}

.constra-header {
  background-color: #003366;
  padding: 20px 0;
  color: white;
  text-align: center;
}

.constra-hero {
  background-color: #003366;
  color: white;
  padding: 50px 20px;
  text-align: center;
}

.constra-hero h1 {
  font-size: 48px;
  margin: 0;
}

.constra-hero p {
  font-size: 24px;
  margin: 10px 0;
}

.constra-button {
  display: inline-block;
  padding: 10px 20px;
  color: #003366;
  background-color: #e6f7ff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: bold;
}

.constra-button:hover {
  background-color: #e0e0e0;
}

.constra-clients {
  padding: 50px 20px;
  text-align: center;
  background-color: #e6f7ff;
  /* Light blue background */
}
.constra-client-img{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.constra-clients h2 {
  margin-bottom: 30px;
  color: black;
}

.constra-clients img {
  max-width: 150px;
  margin: 0 15px;
}

.constra-features {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.constra-feature-section {
  display: flex;
  justify-content: space-between;
  padding: 50px 220px;
  align-items: center;
  background-color: #ffffff;
  /* Light blue background */
}

.constra-feature-text {
  width: 50%;
  padding: 20px;
}

.constra-feature-text h3 {
  color: #006699;
  font-size: 28px;
  margin-bottom: 10px;
}

.constra-feature-text p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: black;
}

.constra-feature-text {
  width: 50%;
  text-align: center;
}

.constra-feature-text img {
  max-width: 100%;
  border-radius: 10px;
}

.constra-outcomes {
  padding: 50px 20px;
  background-color: #003366;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.constra-outcomes .constra-content {
  max-width: 50%;
  text-align: center;
}

.constra-outcomes .constra-content h2 {
  color: #8fd3e9;
  font-size: 36px;
}

.constra-outcomes .constra-content p {
  margin: 20px 0;
}

.outcome-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 50%;
}

.outcome-stats .constra-stat {
  background-color: #0055a5;
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  text-align: center;
}

.outcome-stats .constra-stat h3 {
  margin: 0;
  font-size: 36px;
}

.outcome-stats .constra-stat p {
  margin: 10px 0 0;
}

.constra-testimonials {
  padding: 50px 20px;
  background-color: #003366;
  color: white;
  text-align: center;
}

.constra-testimonial {
  display: inline-block;
  width: 45%;
  padding: 20px;
  margin: 10px;
  background-color: #0055a5;
  border-radius: 10px;
  color: white;
}


@media screen and (max-width: 550px) {}

@media screen and (max-width: 1000px) {
  .constra-feature-section{
    padding: 0px;
    flex-direction: column-reverse;
  }
  .constra-testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .constra-outcomes{
    flex-direction:column;
    gap: 30px;
  }
}
</style>