<template>
  <div class="userInfo">
    <h2 style="margin-bottom: 16px; color: #9eea22;">
      <template v-if="findType == 'tenders'">{{ $filters.msg('FIND_WORK_ON_THEKA') }}</template> 
      <template v-else-if="findType == 'jobs'"> {{ $filters.msg('Find works on Bandhoo') }}</template> 
      <template v-else> {{ $filters.msg('Get Best Quotations') }} </template>
    </h2>
    <form id="modalForm" action="">
      <div v-if="formUsedFor == 'contractors'" style="display: flex; justify-content: space-between;">
    </div>
      <div>
        <i class="material-icons icon">person</i>
        <input class="enterName" type="text" v-model="userName" pattern=".+" oninput="validate(this)"
          :placeholder="$t('Full Name')" required>
      </div>
      <div>
        <i class="material-icons icon">engineering</i>
        <select class="selectSkill" oninput="validate(this)" v-model="userSkill" required pattern="[^ ].+">
          <option value=" " disabled>{{ $t('Choose Skill') }}</option>
          <template v-if="formUsedFor == 'jobs'">
            <option v-for="(skill, index) in workerSkills" :value="skill.value" :key="index">{{ $t(skill.value) }} </option>
          </template>
          <template v-else>
            <option v-for="(skill, index) in contractorSkills" :value="skill.value" :key="index">{{ $t(skill.value) }} </option>
          </template>
        </select>
      </div>
      <div id="pincodeContainer" class="pincodeContainer">
        <div>
          <i class="material-icons icon">location_on</i>
          <input class="enterPincode" type="text" :id="scope + 'websitepincode'" v-model="pin" pattern="[0-9]{6}" info="true"
            @input="enterWebsitePincode($event)" :placeholder="$t('Location/Pincode')" required :list="scope + 'websitepincodelist'">
          <img @click="startSpeechPincode($event)" class="micIcon icon" alt="Start" :id="scope + 'websitepincode.start_img'"
            src="https://www.google.com/intl/en/chrome/assets/common/images/content/mic.gif">
          <datalist :id="scope + 'websitepincodelist'" style="display: inline-block;"></datalist>
        </div>
        <div :id="scope + 'websitepincode.info'" style="display:block" class="pincodeInfo"></div>
      </div>
      <template v-if="findType == 'contractors'">
      <div>
          <i class="material-icons icon">email</i>
          <input class="enterEmail" type="text" v-model="userEmail" pattern="\S+@\S+\.\S+" oninput="validate(this)"
            :placeholder="$t('Email')" required>
        </div>
        <div style="display: flex; align-items:center;">
          <i class="material-icons icon">description</i>
          <textarea class="enterDescription" type="text" v-model="requirementsDescription" pattern=".+" oninput="validate(this)"
            :placeholder="$t('Requirements')" required></textarea>
        </div>
      </template>
      <div v-else-if="formUsedFor != 'jobs'  && formUsedFor != 'tenders'" style="margin: 15px 0;">
        <p style="line-height: 34px;">{{ $t("To find best tenders, enter mobile and verify otp") }}</p>
      </div>
      <div style="display: flex; align-items: center; gap: 8px;">
        <i class="material-icons icon">call</i>
        <input class="enterMobile" type="number" v-model="userMobile" @input="validateMobile($event)" pattern="[0-9]{10}"
          :placeholder="$t('Mobile')" required>
      </div>
      <div>
        <button type="button" :id="scope + 'otpBtn'" class="getOtpBtn" @click="getOtp($event)" disabled>{{ $t('Get OTP') }}</button>
        <span :id="scope + 'getOtpMsg'" style="font-size: 12px; font-weight: 500; margin-left: 4px;">{{ otpMsg }}</span>
      </div>
      <div>
        <input class="enterOtp" type="number" pattern="[0-9]{6}" v-model="userOtp" @input="validateVerifyOtp($event)"
          :placeholder="$t('OTP')" required>
        <span id="otpVerificationMsg" style="color: red; font-size: 14px; font-weight: 500; margin-left: 4px;">{{
          otpVerificationMsg }}</span>
      </div>
      <div style="display: flex; align-items: center; gap: 8px;">
        <button id="enter" class="verifyBtn" type="button" disabled @click="verifyOtp($event)">{{ $filters.msg('Enter OTP') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { requestDemo, contractorSkills, workerSkills } from '@/assets/db/static-data';
import { BANDHOO_WEB_SERVER, SERVER } from "@/assets/db/config";
export default {
  name: "FindWorksForm",
  props: ['scope', 'formUsedFor', 'skill', 'pincode'],
  data() {
    return {
      requestDemo,
      contractorSkills,
      workerSkills,
      userName: '',
      userEmail: '',
      userMobile: '',
      userOtp: '',
      otpMsg: '',
      otpVerificationMsg: '',
      userSkill: this.skill ? this.skill : ' ',
      pin: this.pincode ? this.pincode : null,
      findType: this.formUsedFor
    }
  },
  computed: {
    requirementsDescription(){
      return this.$t('Requirements Description', {skill: this.userSkill, pincode: this.pin})
    }
  },
  methods: {
    validateMobile(event) {
      validate(event.target, true, this.scope + 'otpBtn')
      $(this.scope+ 'getOtpMsg').style.display = "none";
    },
    validateVerifyOtp(event) {
      validate(event.target)
      $('otpVerificationMsg').style.display = "none";
    },
    enterWebsitePincode(event) {
      event.target.setAttribute("valid", isPincode(event.target.id, 1, 'coarse'));
      $('pincodeContainer').style.height = "50px";
      fetchPincode(SERVER, event.target.id, 1, 'coarse');
    },
    startSpeechPincode(event) {
      new speechRecognizer($(this.scope + "websitepincode"), 'start_img', this.scope + "websitepincode.");
      startSpeech(event);
      return false;
    },
    getOtp(event) {
      signalWaitMode()
      this.sendInformationToGoogleAnalitics('website_' + this.findType + '_otprequest')
      let url = SERVER + `websitenewcontractorcheckin`;
      $(event.target.id).disabled = true;
      $(this.scope + 'getOtpMsg').style.display = "inline-block";
      ajaxFetchRequest(event, { action: 'otp', 'mobile': this.userMobile }, url, 'GET', 'json', 'include')
        .then((result) => {
          console.log(result, "result");
          this.otpMsg = 'OTP sent successfully!'
          signalWaitOver()
        })
        .catch((error) => {
          this.otpMsg = 'Something went wrong!'
          signalWaitOver();
          console.log(error);
        })
    },
    verifyOtp(event) {
      event.preventDefault();
      signalWaitMode();
      this.sendInformationToGoogleAnalitics('website_' + this.findType +'_otpverify')
      let url = SERVER + `websitenewcontractorcheckin?action=login&mobile=${this.userMobile}&pin=${this.userOtp}`;
      ajaxFetchRequest(event, null, url, 'POST', 'json')
        .then((result) => {
          console.log(result, "result");
          let params = {
            mobile: this.userMobile,
            email: this.userEmail,
            capabilityId: this.userSkill,
            name: this.userName,
            pincode: this.pin,
            userRole: this.formUsedFor == 'jobs' ? 'Worker' : 'Contractor',
            companyType:  this.formUsedFor == 'tenders' ? 'Thekedar' : 'Contractor',
            note: this.requirementsDescription,
            hl: this.$i18n.locale
          }
          let redirectionUrl = makeUrl('findworks.html', params)
          if (result.otpVerified == true) {
            const url = BANDHOO_WEB_SERVER + redirectionUrl;
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            this.$gtag.event('conversion', {
              'send_to': 'AW-665052387/qnwOCIOEsIoZEOPJj70C',
              'event_callback': callback
           });
           return false;
          } else {
            $('otpVerificationMsg').style.display = "block";
            this.otpVerificationMsg = 'OTP is wrong!, Please enter correct OTP';
            signalWaitOver()
          }
        })
        .catch((error) => {
          this.otpMsg = 'Something went wrong!';
          signalWaitOver();
          console.log("error", error);
        })
    },
    sendInformationToGoogleAnalitics(information) {
      console.log("Sending information to google analytics...  " + information);
      this.$gtag.event(information);
      console.log("Information sent to google analytics...");
    }
  },
  mounted(){
    let pincodeId = this.scope + 'websitepincode'
    if($(pincodeId) && $(pincodeId).value){
      $(pincodeId).setAttribute("valid", isPincode(pincodeId, 1, 'coarse'));
      $('pincodeContainer').style.height = "50px";
      fetchPincode(SERVER, pincodeId, 1, 'coarse');
    }
  }
}
</script>

<style scoped>
.userInfo {
  width: 100%;
  position: relative;
}

.userInfo>form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 1.1rem;
}

.userInfo input,
.userInfo textarea,
.userInfo select {
  width: 100%;
  background-color: black;
  color: white;
  overflow: hidden;
}
.userInfo textarea {
  /* height: 36px; */
  min-width: 100%;
  line-height: 16px;
}

.userInfo #worker,
.userInfo #contractors ,
.userInfo #contractor ,
.userInfo #jobs {
  width: 16px;
}

.roleText {
  font-size: .85rem;
}

.enterName,
.enterPincode,
.selectSkill,
.enterEmail,
.enterDescription,
.enterMobile {
  border-radius: 4px;
  padding: 8px 0;
  padding-left: 36px;
  border: none;
  border: 1px solid rgb(255, 255, 255);
}

.pincodeContainer {
  position: relative;
}

.pincodeInfo {
  text-transform: capitalize;
  border-top: none;
  position: absolute;
  font-size: .8rem;
}


.getOtpBtn {
  border-radius: 4px;
  padding: 4px 8px;
  color: white;
}


.enterOtp {
  border-radius: 4px;
  padding: 8px;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.verifyBtn {
  width: 100%;
  padding: 8px 8px;
  min-width: 120px;
  border-radius: 4px;
  color: white;
}

.verifyBtn:not([disabled]),
.getOtpBtn:not([disabled]) {
  background-color: #25d366;
}

.icon {
  position: absolute;
  padding: 6px;
}

.micIcon {
  width: 38px;
  right: 0px;
  border-radius: 50%;
  cursor: pointer;
}
</style>