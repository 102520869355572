<template>
  <div class="searchJob">
    <div style="display: flex">
      <select id="selectSkill"
        class="selectSkill"
        name="searchedSkill"
        :value="searchedSkill"
        @input="selectSkill($event.target)"
        required>
        <option v-for="(skill, index) in skills" :value="skill.value" :key="index">
          {{ $filters.msg(skill.value) }}
        </option>
      </select>
    </div>
    <div class="searchItemContainer">
      <div class="searchInput">
        <input
          ref="pincode"
          class="enterPincode"
          type="text"
          name="pincode"
          id="pincode"
          :value="pin"
          pattern="[0-9]{6}"
          @input="enterPincode($event.target)"
          :placeholder="$filters.msg('Location/Pincode')"
          required
          list="pincodelist"
          valid="false"
        />
        <!-- Todo - Mic icon has to be improved -->
        <img
          @click="startSpeech($event)"
          class="micIcon"
          alt="Start"
          id="pincode.start_img"
          src="https://www.google.com/intl/en/chrome/assets/common/images/content/mic.gif"
        />
        <datalist id="pincodelist" style="display: inline-block"></datalist>
      </div>
      <div class="searchButtonContainer">
        <button
          id="searchBtn"
          type="button"
          @click="makeSearchRequest(event)"
          class="searchBtn"
          disabled>
          <i class="material-icons">search</i>{{$filters.msg('Search')}}
        </button>
      </div>
      <div id="pincode.info" class="pincodeInfo info"></div>
    </div>
    <input type="hidden" id="isValidPincode" name="isValidPincode" :value="isValidPincode()">
  </div>
</template>

<script>
import { BANDHOO_WEB_SERVER, SERVER } from "@/assets/db/config";
export default {
  name: "SearchComponent",
  props: ["skills", "searchedSkill", "pin", "searchFunction", 'notFrame'],
  watch:{
    pin:{
      //To update pincdode info
      handler(){
       this.enterPincode(this.$refs.pincode);
       return;
      },
      flush: 'post'
    }
  },
  methods: {
    isValidPincode(){
     return this.$refs.pincode ? this.$refs.pincode.validity.valid : false;
    },
    selectSkill(input) {
      this.$emit("update:searchedSkill", input.value);
    },
    enterPincode(input) {
      this.$emit("update:pin", input.value);
      input.setAttribute(
        "valid",
        isPincode(input.id, 1, "coarse")
      );
      fetchPincode(SERVER, input.id, 1, "coarse", "searchBtn");
    },
    startSpeech(event) {
      new speechRecognizer(
        document.getElementById("pincode"),
        "start_img",
        "pincode."
      );
      startSpeech(event);
      return false;
    },
    makeSearchRequest() {
      this.searchFunction();
    },
    handleMessage(event){
      event.stopPropagation();
      if(event.data && BANDHOO_WEB_SERVER.includes(event.origin)){
        $('selectSkill').value = event.data.skill || this.searchedSkill;
        $('pincode').value = event.data.pincode ? event.data.pincode.substring(0, 6) : this.pin;
        this.enterPincode($('pincode'));
        this.selectSkill($('selectSkill'));
        this.searchFunction();
      }
    }
  },
  mounted() {
    const speech = new speechRecognizer(document.getElementById("pincode"), "start_img", "pincode.");
    //To get data from iframe website search
    if(window.addEventListener) {
        window.addEventListener("message", this.handleMessage);
      }else {
        window.attachEvent("onmessage", this.handleMessage);
     }
  },
  beforeUnmount () {
     window.removeEventListener('message', this.handleMessage)
  },
  created(){
   if(!this.notFrame){//To post data to IFrame
    this.$watch(data => [data.pin, data.searchedSkill], value => {
      let formDataAsJson = Object.fromEntries(new FormData(this.$refs.pincode.form));
       parent.postMessage(formDataAsJson, BANDHOO_WEB_SERVER);
    }, {
      flush:"post" //After DOM updated
    }) 
   }
  }
};
</script>

<style scoped>
.searchJob {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
  margin-bottom: 40px;
  border: none;
}

.selectSkill {
  background-color: rgb(240, 240, 240);
  padding: 4px 8px;
  border: none;
  height: 40px;
  font-size: 1rem;
}

.searchItemContainer {
  display: flex;
  align-items: center;
  position: relative;
}
.searchBtn {
  height: 30px;
  width: 100%;
}
.sendEnquiry,
.sendEnquiry button {
  width: 100%;
}
.searchInput {
  display: flex;
  align-items: center;
  background: white;
}
.searchButtonContainer {
  display: flex;
  gap: 16px;
}

.enterPincode {
  height: 40px;
  padding: 0 16px;
  letter-spacing: 1px;
  font-size: 1rem;
}

.searchBtn {
  width: 120px;
  gap: 4px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 2px 8px;
}

.searchBtn:not([disabled]) {
  background-color: #25d366;
}
.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
}
.micIcon {
  height: 37px;
  border-radius: 1px;
  border: 1px solid gray;
  cursor: pointer;
}
.pincodeInfo {
  text-transform: capitalize;
  border-top: none;
  position: absolute;
  bottom: -27px;
}

/* media queries */

@media screen and (max-width: 550px) {
  .selectSkill {
    padding: 4px;
    height: 30px;
    font-size: 0.85rem;
    width: fit-content;
  }

  .searchJob input {
    padding: 4px;
    width: 110px;
    letter-spacing: normal;
    height: 30px;
  }

  .info {
    font-size: 0.85rem;
    gap: 2px;
  }
  .searchBtn {
    height: 30px;
    width: 100%;
  }

  .enquiry > div > button {
    border-radius: 4px;
    padding: 4px 6px;
    border: none;
    font-size: 0.75rem;
  }

  .sendEnquiry,
  .sendEnquiry button {
    width: 100%;
  }

  .breadCrumbsContainer {
    flex-direction: column;
  }
  .searchButtonContainer {
    width: 250px;
    flex-direction: column;
    margin-top: 20px;
  }
  .pincodeInfo{
    bottom: 34px;
  }
}

@media screen and (max-width: 600px) {
  .searchJob {
    flex-direction: column;
    gap: 5px;
  }

  .selectSkill {
    width: 250px;
  }

  .searchJob > div {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }

  .searchInput {
    width: 250px;
  }

  .searchInput > input {
    width: 250px;
  }

  .searchInput > img {
    height: 30px;
  }

  .searchButtonContainer {
    width: 250px;
    flex-direction: column;
    margin-top: 20px;
  }
  .pincodeInfo{
    bottom: 34px;
  }
}
</style>
